/* -------------------------------------------
    Footer
        - Footer top
        - Footer middle
        - Footer bottom
        - Copyright
        - Widget newsletter
        - Widget about
        - Sticky Footer
---------------------------------------------- */

// Variables
@include set-default(
  (
    footer: (
      font-family: $font-family,
      font-size: 1.3rem,
      letter-spacing: false,
      color: false,
      _link-active-color: #fff,

      top: (
        padding: 4rem 0,
        border-top: false,
        border-bottom: 1px solid #333,
        background: false,
      ),
      middle: (
        padding: 8.2rem 1.8rem 7.5rem,
        max-width: 1220px,
        margin: auto,
        letter-spacing: false,
        background: false,
        widget: (
          margin-bottom: 3rem,
          title: (
            padding: 0.6rem 0,
            margin-bottom: 0.9rem,
            font-family: inherit,
            font-size: 1.6rem,
            font-weight: 600,
            letter-spacing: 0,
            line-height: false,
            text-transform: capitalize,
            color: #e1e1e1,
          ),
          body: (
            padding: 8px 0 0,
            color: $primary-color,
          ),
          list-item: (
            line-height: 1.2,
            margin-bottom: 15px,
          ),
          label: (
            font-family: false,
            font-size: false,
            font-weight: 500,
            letter-spacing: false,
            line-height: false,
            text-transform: uppercase,
            color: #ccc,
          ),
        ),
      ),
      main: (
        padding: 2.6rem 0 0.4rem,
      ),
      bottom: (
        padding: 2rem 7.6rem,
        background: false,
        background-color: #71bb71,
      ),
      copyright: (
        font-family: false,
        font-size: 1.4rem,
        font-weight: 500,
        color: false,
        letter-spacing: 0.01em,
        line-height: 1.5,
      ),
      social-link: (
        letter-spacing: 0.005em,
        color: #ffffffe6,
        border: 2px solid #ffffffe6,
        hover: (
          color: #ffffffe6,
        ),
      ),
      about: (
        logo: (
          margin-bottom: 1.6rem,
        ),
        p: (
          margin-bottom: 3rem,
          color: #999,
          line-height: 1.85,
          letter-spacing: 0.025em,
        ),
      ),
      newsletter: (
        title: (
          padding: 0,
          margin-bottom: 0.5rem,
          font-family: inherit,
          font-size: 2rem,
          font-weight: 600,
          letter-spacing: 0.015em,
          text-transform: inherit,
          line-height: 1,
          color: #fff,
        ),
        desc: (
          margin-bottom: 0.1rem,
          font-family: inherit,
          font-size: 1.4rem,
          font-weight: false,
          letter-spacing: 0.005em,
          text-transform: false,
          line-height: 1.23,
          color: #999,
        ),
        form: (
          max-width: 48rem,
        ),
        input: (
          padding: false,
          min-height: 100%,
          border: 0,
          border-radius: 0.3rem,
          color: $grey-color,
          background: #2c2c2c,
        ),
        btn: (
          padding: false,
        ),
      ),
    ),
  )
);

.footer-mid {
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 15px;
  }
}
// Footer
.footer {
  @include print_css(footer);
  p {
    font-size: inherit;
  }

  .widget-title {
    border-bottom: none;
  }
}
.logo-footer,
.logo-footer img {
  display: block;
}
// Footer Top
.footer-top {
  @include print_css(footer, top);
}

// Footer Middle
.footer-middle {
  @include print_css(footer, middle);

  .widget {
    @include print_css(footer, middle, widget);
  }
  .widget-title {
    @include print_css(footer, middle, widget, title);
  }
  .widget-body {
    @include print_css(footer, middle, widget, body);
    li {
      @include print_css(footer, middle, widget, list-item);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    @include print_css(footer, middle, widget, label);
  }
}

.widget.widget-info a {
  font-weight: 500;
}

// Footer Main
.footer-main {
  @include print_css(footer, main);
}

// Footer Bottom
.footer-bottom {
  &,
  .container,
  .container-fluid {
    display: flex;
    align-items: center;
  }
  @include print_css(footer, bottom);

  .footer-left,
  .footer-right {
    flex: 1;
  }
  .footer-left {
    display: flex;
  }
  .footer-right {
    display: flex;
    justify-content: flex-end;
  }
}

// Responsive
@include mq("lg", "max") {
  .footer-middle {
    .logo-footer {
      margin-bottom: 2.7rem;
      text-align: center;
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .newsletter-info {
    margin-bottom: 2rem;
  }
  .footer-middle {
    padding: 6.8rem 0 4.8rem;
    .widget-body {
      padding: 0;
    }
  }
  .footer-bottom {
    &,
    > .container {
      display: block;
    }
    .footer-left {
      justify-content: center;
    }
    .footer-right {
      justify-content: center;
      padding-bottom: 0.2rem;
    }
    .footer-left,
    .footer-center {
      margin-bottom: 1.5rem;
    }
  }
  .footer-center {
    text-align: center;
  }
}

// Copyright Text
.footer .copyright {
  color: #ffffffe6;
  margin: 0;
  @include print_css(footer, copyright);
}

.payment {
  img {
    display: block;
  }
}

// Sticky Footer
.sticky-footer {
  display: flex;
  > * {
    flex: 1;
  }
  .search-toggle {
    padding: 0;
    color: inherit;
  }
  .header-search.show,
  .header-search:hover {
    color: #222;
  }
  .hs-toggle .input-wrapper {
    min-width: 29rem;
    right: 1.5rem;
    margin-bottom: 2rem;
  }
}

@include mq(sm) {
  .sticky-footer {
    padding: 0 4rem;
  }
}

@include mq(md) {
  .sticky-footer {
    display: none;
  }
}

@include mq(lg, max) {
  .footer-middle .row > div:last-child .widget {
    margin-bottom: 1rem;
  }
  .footer-middle {
    .widget {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
@include mq(sm, max) {
  .footer-top {
    padding: 6rem 0;
    .input-wrapper {
      display: block;
      margin-bottom: 5px;
    }
    .btn {
      margin-top: 10px;
    }
  }
}
