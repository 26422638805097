/* -------------------------------------------
    Account
---------------------------------------------- */

.account {
  line-height: 2.15;

  .title {
    font-size: 3rem;
    letter-spacing: 0.035em;
  }

  p {
    font-family: $font-family;
    line-height: 2.15;
    letter-spacing: 0.017em;

    span {
      color: #333;
    }

    &:first-child {
      letter-spacing: 0.005em;
    }

    .link-to-tab {
      text-decoration: underline;
    }
  }

  .dashboard {
    p {
      color: #777;
    }
  }

  .btn.btn-dark {
    padding: 0.93em 1.97em;
  }

  label {
    display: block;
    padding-left: 0.2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    line-height: 1;
  }

  .form-control {
    font-size: 1.3rem;
    font-family: $font-family;
    transition: background-color 0.3s, border-color 0.3s;
    color: #999;
    border-radius: 0.3rem;

    &:focus {
      border-color: $dark-color;
    }
  }

  .nav-tabs {
    width: auto;
    border-right: none;
  }

  .nav-item {
    border-bottom: 1px solid #eee;
    color: #222;

    a {
      display: block;
      padding: 1.7rem 0.8rem 1.6rem;
      margin: 0;
      font-weight: 600;
      letter-spacing: 0.015em;
      line-height: 1;
      text-transform: none;
    }

    &:hover {
      .nav-link {
        color: $primary-color;
      }
    }

    &.show .nav-link,
    .nav-link.active {
      color: $primary-color;
    }
  }

  .tab-pane {
    padding: 0.8rem 0 0;
  }

  thead {
    text-align: left;

    th {
      padding: 1.5rem 0;
      // background-color: #f4f4f4;
    }
  }

  tbody {
    td {
      padding: 1.5rem 1rem 1.5rem 0;
    }

    tr:nth-child(2n) td {
      // background-color: #f4f4f4;
    }
  }

  td:first-child {
    padding-left: 1rem;
  }

  .order-total {
    width: 25.9rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: $body-color;
  }

  fieldset {
    margin: 7rem 0 2rem;
    padding: 2rem 2rem 1rem;
    border: 1px solid #e1e1e1;
  }

  legend {
    color: $dark-color;
  }

  .btn {
    padding: 1em 1.87em;
  }

  .order-table {
    border: 1px solid $border-color-light;
  }

  .order-action .btn {
    padding: 0;
  }

  .account-active-link {
    border-right: 4px solid $primary-color;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(0, 0, 0, 0.08167016806722693) 100%
    );
  }
}

.radio-icon {
  margin-top: 4px;
}

.downloads,
.card-address {
  .btn {
    padding: 0;
  }
}

.card-address .btn i {
  font-size: 1.4rem;
}

.mobile-account-tab {
  justify-content: space-between;
  padding: 1.55rem 8px;
  margin: 0px 10px;
  border-bottom: 1px solid $border-color;

  .tab-label {
    font-size: 1.6rem;
    line-height: 1;
    margin-left: 10px !important;
  }
}

.mobile-tab-header {
  padding: 1.55rem 8px;

  .header-label {
    font-size: 1.6rem;
    line-height: 1;
    margin-left: 10px !important;
  }
}

.mobile-tabs-content {
  padding: 1rem 1rem 5rem 1rem !important;
}

.order-number {
  width: 11.4rem;
  color: #000;
}

.order-date {
  width: 24.8rem;
  color: #666;
}

.order-status {
  width: 14.7rem;
  font-size: 1.2rem;
  color: #222;
  font-weight: 900;

  span {
    display: inline-block;
    padding: 0.1rem 0.5rem;
    border-radius: 0.3rem;
    line-height: 1.9;
  }
}

.card-address {
  position: relative;

  .add-lables-values {
    min-height: 122px;

    .checkout-email-lable {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2 !important;
      width: 23rem;
      overflow: hidden;
      display: block;
    }

    span {
      color: #666;
    }

    .add-wrap {
      min-height: 7.5rem;
    }

    .add-address {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  .add-bottom-btn {
    line-height: normal;
    background-color: #c0ca7f;
    font-weight: 600;
    color: #fff;
    border-radius: 6px;
    font-size: medium;
    width: fit-content;
    padding: 6px 12px;
    //	position: absolute;
    bottom: 1rem;
  }
}

.mobile-checkout-address {
  padding: 1.5rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  color: $primary-color;

  .mobile-address-heading {
    .address-user-name {
      color: $primary-color !important;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .mobile-address-label {
    color: $grey-color;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    font-size: 1.25rem;
  }

  .btn-change {
    padding: 0.8rem !important;
  }
}

@include mq(md, max) {
  .account {
    .nav-tabs {
      width: 100%;
    }

    .tab-content {
      padding-left: 1.5rem;
    }
  }
}

@include mq(xs, max) {
  .order-action,
  .order-table thead th:last-child {
    // display: none;
  }
}
