.faq {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Horizontally center the title and accordions */
  align-items: center; /* Vertically center the title and accordions */

  .faq-title {
    text-align: center; /* Center the text within the title */
    width: 80%; /* Adjust the width as needed */
    margin-bottom: 3rem;
    font-size: 36px;
    color: black;

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  .faq-accordion {
    border-bottom: 1px solid #e1e1e1;
    width: 60vw; /* Adjust the width of the accordions */
    /* Add any additional styles for the accordions */
    @media (max-width: 767px) {
      width: 90vw;
    }
  }
}
