/* -------------------------------------------
    Contact Page
---------------------------------------------- */

.contact-us {
  .breadcrumb {
    padding: 1.2rem 0 1.1rem;

    li:last-child {
      color: $body-color;
    }

    li:not(:last-child) {
      a,
      &::after {
        opacity: 0.7;
      }
    }
  }

  .page-header {
    height: 30.2rem;

    @media (max-width: 767px) {
      height: 20rem;
    }
  }

  .page-title {
    font-size: 5rem;
  }

  .grey-section {
    background-color: #f7f7f9;
  }

  .store-section .title {
    font-size: 3rem;
  }
}

.contact-us .grey-section {
  padding: 2.5rem 3.2rem 1.4rem;

  h4 {
    font-size: 2rem;
    line-height: 1.1;
  }

  p {
    margin-bottom: 3.2rem;
    line-height: 1.72;
  }
}

.contact-section {
  form {
    h4 {
      margin-bottom: 0.3rem;
    }

    p {
      margin-bottom: 2.4rem;
      font-size: 1.3rem;
    }
  }

  .form-control {
    border-radius: 0.3rem;

    &::placeholder {
      color: #999;
    }
  }

  textarea {
    min-height: 15rem;
    padding: 1rem 2rem;
  }

  .btn i {
    font-size: 1.6rem;
    margin-left: 1rem;
    margin-bottom: 0.2rem;

    &::before {
      font-weight: 600;
    }
  }
}

.contact-us-container {
  width: 50%;

  @media (max-width: 1099px) {
    width: 66%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.accordion-bottom {
  border-bottom: 1px solid #e1e1e1;
}

.section-wrapper {
  padding: 28px 40px 72px;
}

.section-background {
  background-color: #fcfaf7;
  padding: 36px 0px;

  @media (max-width: 767px) {
    padding: 28.8px 0;
  }
  @media (max-width: 767px) {
    padding: 21.6px 0;
  }
}

.accordion-padding {
  padding: 8px 0px;
}

.contact-image {
  background-image: url("~/public/images/page-header/about-us.webp");
}
