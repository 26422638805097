/* -------------------------------------------
        Base
    ---------------------------------------------- */

// Variables
@include set-default(
  (
    base: (
      // max-width of '.container'
      _container-width: 1220px,
      // max-width of '.container-fluid'
      _container-fluid-width: 1820px,
      // grid spaces
      _gutter-lg: 15px,
      _gutter-md: 10px,
      _gutter-sm: 5px,
      _gutter-xs: 1px,
      // background of grey section
      _grey-section-bg: #f6f7f9,
      // Body
      body:
        (
          font-family: $font-family,
          font-size: 1.4rem,
          line-height: 1.6,
          color: $body-color
        ),
      page-wrapper: (margin-left: false, margin-right: false),
      // ScrollTop
      scroll-top: (background-color: #fff),
    ),
  )
);

*,
::after,
::before {
  touch-action: pan-x pan-y !important;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  // overflow-x: hidden;
  @include print_css(base, body);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  touch-action: pan-x pan-y;
  letter-spacing: 0.05rem !important;
}

main {
  display: block;
  position: relative;
}

body:not(.loaded) * {
  transition: all 0s !important;
}

.landing-pages-container {
  max-width: 670px !important;

  p {
    font-size: 16px;
  }
}

.container1 {
  max-width: 1250px;
  margin-inline: auto;
}

.spin-loader {
  width: 17px;
  height: 17px;
  -webkit-animation: spin 650ms infinite linear;
  animation: spin 650ms infinite linear;
  border-radius: 75%;
  border: 2px solid #fff !important;
  border-bottom-color: transparent !important;
  z-index: 9999;
}

.loaded .page-wrapper {
  opacity: 1;
}

.App {
  text-align: center;
  margin-top: 50px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.page-wrapper {
  opacity: 0;
  position: relative;
  transition: margin 0.4s, opacity 0.5s;
  @include print_css(base, page-wrapper);
  min-height: 100vh;
}

.custom-text-container {
  display: flex;
  align-items: center;
  /* Center the content vertically */
  justify-content: center;
  padding-top: 36px;
  padding-right: 30px;
  padding-left: 30px;
  /* Center the content horizontally */
  @media (max-width: 767px) {
    padding-top: 0;
  }

  @media (max-width: 479px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.custom-text-block {
  text-align: center;
}

.heading {
  font-size: 34.21px;
  font-weight: 530 !important;
  @media (min-width: 1099px) {
    font-size: 47.81px;
    margin: 0 0 35px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    margin: 0 0 18px;
  }
}

.custom-small-text {
  max-width: 600px;
  margin: 0 auto !important;
  font-size: 13.7px !important;
  font-weight: 330 !important;

  @media (min-width: 1099px) {
    margin: 0 81.8px !important;
    font-size: 14.53px !important;
  }
}

.section-padding {
  padding: 36px 50px !important;

  @media only screen and (min-width: 768px) and (max-width: 1099px) {
    padding: 28.8px 40px !important;
  }

  @media only screen and (max-width: 767px) {
    padding: 21.6px 30px !important;
  }
}

.section-padding.wrapper {
  padding: 0px 50px !important;
  @media only screen and (min-width: 768px) and (max-width: 1099px) {
    padding: 0px 40px !important;
  }

  @media only screen and (max-width: 767px) {
    padding: 0px 30px !important;
  }
}

.section-padding.wrap {
  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.home-subtitle {
  font-size: 36px;
  padding: 0px 50px 20px;
  margin: 0px 0px 48px;
  text-align: center;
  font-weight: 500 !important;
  position: relative;

  @media (max-width: 1099px) {
    padding: 0px 50px 18px;
    margin: 0px 0px 38.4px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    padding: 0px 0px 16px;
    margin: 0px 0px 28.8px;
  }
}

.home-subtitle:after {
  content: "";
  margin: auto;
  width: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #111c19;
  opacity: 0.2;
  box-sizing: border-box;
}

.page-content {
  color: $text-color-1;
}

table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 0;
  }
}

.image-card-wrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-bottom: 50px;

  @media (min-width: 1450px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.image-card-wrapper.wrap {
  @media (max-width: 767px) {
    flex-direction: column !important;
    min-height: auto !important;
  }
}

.image-card-wrapper.wrapper {
  margin-bottom: 0;
  column-gap: 60px;
}

.blog-image {
  min-height: 450px !important;
}

.blog-title {
  @media (max-width: 575px) {
    font-size: 26px !important;
    margin-bottom: 10px;
  }
}

.row-reverse {
  flex-direction: row-reverse;
}

.image-card-image-wrapper {
  flex: 1;
  // max-height: 60vh;
  // max-width: 40vw;
  // margin-right: 20px;
  padding: 0;
  // height: 557px;
}

.image-card-image-wrapper img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 40px;
  min-height: 400px;
  // object-fit: fill;
}

.image-card-content {
  padding: 0 50px !important;
  text-align: left;
  flex: 1;
  margin-left: 0;
  // max-height: 60vh;
  max-width: 40vw;
  justify-content: center;
  background-color: #f7f6f2;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  border-radius: 40px;
  min-height: 400px;

  @media (max-width: 767px) {
    padding: 15px 40px !important;
  }
  @media (max-width: 575px) {
    padding: 15px 30px !important;
  }
}

.detail-page {
  padding: 0 !important;
  background-color: #fff;
}

.tagline {
  text-align: left !important;
  font-weight: 500;
  font-size: small;
  color: #67aa67 !important;
  margin-bottom: 12px;
}

@media (max-width: 767px) {
  .blog-mobile {
    background-color: #f7f6f2;
    margin: 20px;
    border-radius: 40px;
    padding-bottom: 0 !important;

    .image-card-contents {
      background-color: transparent !important;
      padding: 20px 15px !important;
    }

    .blog-main {
    }
  }
}

.image-card-content .blog-title {
  font-size: 39.19px;
  font-weight: 600 !important;
  margin: 0;
  font-weight: 500;
  @media (max-width: 1099px) {
    font-size: 29.12px;
  }
}

.fancy-text {
  display: flex;
  align-items: center;
}

.fancy-text-heading {
  font-weight: 500 !important;
  margin: 0 !important;
}

.image-card-content .price {
  font-size: small;
  margin-bottom: 0;
}

.explore-btn {
  font-size: 13px !important;
  padding: 24px 48px !important;
  width: max-content;

  @media (max-width: 767px) {
    padding: 21px 42px !important;
  }

  @media (max-width: 500px) {
    padding: 12px 42px !important;
  }

  &:hover {
    background-color: #3346410d !important;
  }
}

.w-max {
  width: max-content !important;
}
@media only screen and (max-width: 767px) {
  .image-card-wrapper {
    flex-direction: column !important;
    align-items: center;
  }
  .image-card-wrapper.wrap {
    flex-direction: column-reverse !important;
  }

  .image-card-image-wrapper {
    width: 100vw;
    max-width: 100vw;
    margin-right: 0;
  }

  .image-card-content {
    width: 80vw;
    max-width: 100vw;
    margin-right: 0;
    padding: 10px;
    margin-top: 2px;
    min-height: 390px;
  }

  .image-card-content.wrap {
    @media (max-width: 767px) {
      width: 95vw;
    }
  }

  .image-card-content.wrapper {
    width: 88vw;
    min-height: auto;
  }
}

.custom-container {
  display: flex;
  align-items: center;
}

.image-container {
  flex: 1;
  padding: 10px;
  margin-right: 35px;
}

.content-container {
  flex: 1;
  padding: 10px;
  padding: 0px 50px;

  @media (max-width: 960px) {
    padding: 0px 40px;
  }

  @media (max-width: 767px) {
    padding: 10px 0px 36px;
  }
}

@media screen and (max-width: 767px) {
  .custom-container {
    flex-direction: column;
  }
}

.content-home {
  min-height: 360px;
  background-color: #f7f6f2;
  border-radius: 40px;
}

.text-alignment {
  justify-content: center;
}

.section {
  padding: 7rem 0;
}

.grey-section {
  @include css(background, base, _grey-section-bg);
}

.background-section {
  background-repeat: no-repeat;
}

.parallax {
  background-color: $background-color-2;
}

ul {
  padding-left: 1.5em;
}

.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav ul,
.widget-body,
.widget-body ul,
.list,
.breadcrumb,
.filter-items,
.select-menu > ul,
.dropdown-box,
.pagination,
.nav-filters,
.category ul,
.comments ul,
.product-nav,
.product-tabs > div ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
  // &:hover {
  //     opacity: 70%;
  // }
  &:hover.text-menu {
    text-decoration: underline;
  }
}

li.submenu:target {
  border-bottom: 2px solid rgba(29, 37, 35, 0.1);
  border-bottom-color: rgba(29, 37, 35, 0.1);
}

.mobile-menu {
  a {
    &:hover {
      opacity: 70%;
    }
  }
}

.bestseller-link {
  text-decoration: underline;
  font-size: 16px;
  text-align: center;
}

.space-bottom {
  @media (max-width: 767px) {
    padding-bottom: 0 !important;
  }
}

:focus {
  outline: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid $border-color-light;
}

input {
  -webkit-appearance: none;
  font-size: 16px !important;
  border: 0;
  padding: 0;
  font-family: $font-family;

  &:focus {
    outline: 0;
  }
}

i {
  font-style: normal;
}

button {
  &:focus {
    outline: none;
  }
}

.scrollable,
.sidebar-content {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 7px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
  }
}

.scrollable-light::-webkit-scrollbar-thumb {
  background: rgba(#fff, 0.2);
}

// Overlay Animation
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }
}

@keyframes rotatedelay {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes reveal-1 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-2 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-3 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-4 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

.bounce-loader {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  //margin: -9px 0 0 -35px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;

  //animation: 2s ease-in-out 0s normal both infinite rotatedelay;
  .bounce1,
  .bounce2,
  .bounce3,
  .bounce4 {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    // border-radius: 100%;
    background-color: $primary-color;
    //box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    //animation: 1s ease-in-out 0s normal both infinite bouncedelay;
  }

  .bounce1 {
    animation: 2s infinite reveal-1;
  }

  .bounce2 {
    animation: 2s infinite reveal-2;
  }

  .bounce3 {
    animation: 2s infinite reveal-3;
  }

  .bounce4 {
    animation: 2s infinite reveal-4;
  }

  .bounce3 {
    border: 3px solid $primary-color;
    background-color: transparent;
  }
}

// Animation
.appear-animate {
  transform: translate3d(0, 0, 0) scale(1);
  will-change: transform, filter, opacity;
}

.fade {
  opacity: 0;
  transition: opacity 0.5s;

  &.in {
    opacity: 1;
  }
}

// ScrollTop
.scroll-top {
  position: fixed;
  text-align: center;
  bottom: 30px;
  left: auto;
  right: 30px;
  width: 60px;
  height: 60px;
  font-size: 27px;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
  color: #222;
  transform: translateY(40px);
  border-radius: 3px;
  z-index: 999;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include print_css(base, scroll-top);

  &:hover {
    color: #222;
  }

  i {
    font-weight: 900;
    line-height: inherit;
  }
}

// d-loading
.d-loading {
  display: block;
  position: absolute;
  top: 100%;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border: 2px solid transparent;
  border-top-color: rgb(0, 0, 0);
  border-radius: 50%;
  animation: spin 0.75s infinite linear;

  &:before {
    content: "";
    top: -2px;
    left: -2px;
    position: absolute;
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
    animation: spin 1.5s infinite ease;
  }
}

// toaster
.Toastify {
  .Toastify__toast-body {
    padding: 0 !important;
  }

  .Toastify__toast {
    min-height: fit-content !important;
  }
}

// body {
//     transition: opacity .1s;
//     opacity: 0;

//     &.loaded {
//         opacity: 1;
//     }
// }

@include mq(sm, max) {
  main {
    // margin-top: 60px;

    // &.searchBar {
    //   margin-top: 130px !important;
    // }
  }
}

@include mq(lg, max) {
  #__next {
    overflow: hidden;
  }
}

@include mq(md) {
  .scroll-top.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

// Sticky Content Animation
@keyframes fixedTop {
  0% {
    margin-top: -60px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes fixedBottom {
  from {
    transform: translateY(100%);
    transform-origin: center top 0px;
  }

  to {
    transform: translateY(0);
  }
}

// Sticky Content(new)
.sticky-content {
  &.fix-top {
    top: 0;
  }

  &.fix-bottom {
    bottom: 0;
  }

  &.fixed {
    &.fix-top {
      animation: fixedTop 0.4s;
    }

    &.fix-bottom {
      animation: fixedBottom 0.4s;
      transition: margin-bottom 0.4s;
    }

    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    // transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  }
}

// Load more icon animation (new)
.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
  animation: spin 650ms infinite linear;
  border: 2px solid transparent;
  border-radius: 32px;
  border-top: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.4) !important;
  content: "";
  display: block;
  height: 21px;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 21px;
}

.load-more-overlay {
  position: relative;

  &.loading::after {
    content: "";
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.8;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.riode-rounded-skin {
  .btn,
  .post-calendar,
  .product-hide-details .btn-product,
  .product-hide-details .btn-product-icon,
  .post-single > .post-wrap img,
  .post-single .post-author-detail,
  .post-media,
  .post-framed,
  .minipopup-box,
  .product-category,
  .product-category .category-content,
  .product-with-qty .quantity button,
  .product-wrapper .banner,
  .vendor-widget .vendor-logo,
  .vendor-widget .vendor-product > figure,
  .vendor-widget .vendor-banner {
    border-radius: 3px;
    overflow: hidden;
  }

  .login-popup {
    border-radius: 3px;
  }

  .form-row [type="text"],
  .form-row [type="tel"],
  .form-row [type="password"],
  .form-row [type="email"],
  .form-row textarea {
    border-radius: 3px;
  }

  .btn-link,
  .form-coupon .input-text {
    border-radius: 0;
    overflow: visible;
  }

  .post.post-mask.gradient::before {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
  }

  .mfp-product .product,
  .product-category.cat-type-default {
    border-radius: 10px;
  }
}

.overflow-ellipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

//border
.border-regular {
  border: 1px solid #e1e1e1;
}

.border-cricle {
  border: 1px solid #e1e1e1;
  border-radius: 50%;
}

.text-align-start {
  text-align: start !important;
}

.mobile-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mobile-padding.wrap {
  padding-bottom: 0 !important;
}
.apply-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #969696;
  cursor: pointer;
}

.image-card-wrappers {
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0px;
  }
  gap: 50px;
  .image-wrapper {
    border-radius: 40px;
  }

  .image-min-height {
    min-height: 400px;
  }

  .image-card-contents {
    flex: 1;
    text-align: left;
    margin-left: 0;
    justify-content: center;
    background-color: #f7f6f2;
    display: flex;
    flex-direction: column;
    border-radius: 40px;

    @media (min-width: 768px) {
      padding: 100px 50px !important;
    }
    @media (max-width: 767px) {
      padding: 20px 40px;
    }
    @media (max-width: 575px) {
      padding: 20px 30px;
    }
  }

  .image-card-contents2 {
    @media (max-width: 767px) {
      padding-top: 100% !important;
    }
  }
}
.policy-link {
  color: cadetblue;
}

.marketplace-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  cursor: pointer;
  .marketplace-inner-container {
    background: rgba(23, 179, 27, 0.05);
    display: flex;
    border-radius: 20px;
    padding: 10px;
    padding-bottom: 0px;
    width: 100%;
    gap: 20px;
    justify-content: space-evenly;
    .padding-top-1x {
      padding-top: 6px;
    }
    .margin-right-16 {
      margin-right: 17px;
    }
  }
}

.marketplace-title {
  color: #013220;
  font-size: 13px;
  margin: 0 0 4px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.35em;
  min-height: 2em;
  .marketplace-text {
    color: #013220;
    font-size: 17px !important;
    margin: 0px 0px 3px !important;
  }
}

.w-fit {
  width: fit-content !important;
}
.shrink-0 {
  flex-shrink: 0n !important;
}
.line-height-0 {
  line-height: 0 !important;
}
