/* -------------------------------------------
    @Component - Product Single
---------------------------------------------- */

// Variables
@include set-default(
  (
    product-single: (
      name: (
        margin-top: 0.8rem,
        margin-bottom: 0.6rem,
        font-size: 24.78px,
        font-weight: 700,
        letter-spacing: 0.1rem,
        white-space: normal,
        color: $dark-color,
      ),
      categories: (
        color: #999,
        font-size: 1.3rem,
        font-weight: 400,
        letter-spacing: 0.05rem,
      ),
      price: (
        color: $dark-color,
        font-size: 3rem,
        font-weight: 700,
        letter-spacing: 0.05rem,
      ),
      old-price: (
        font-size: 2.4rem,
        font-weight: 600,
        text-decoration: false,
      ),
      product-meta: (
        color: #999,
        font-size: 1.2rem,
      ),
      product-short-desc: (
        font-family: false,
      ),
      label: (
        color: $primary-color-dark,
      ),
      variation: (
        width: 3rem,
        height: 3rem,
      ),
      btn-cart: (
        max-width: inherit,
        height: 4.5rem,
      ),
      rating: (
        color: $secondary-color,
        review-color: #999,
      ),
    ),
  )
);

// Product Gallery Thumbs
.product-thumbs-wrap,
.product-single-carousel {
  flex-basis: 100%;
  max-width: 100%;
}

.product-single-carousel .owl-nav .owl-prev,
.product-single-carousel .owl-nav .owl-next {
  color: $primary-color-dark;
  border: 0;
}

.product-image {
  position: relative;
  width: 100%;
}

// Product Thumbs Dots
.product-thumbs-wrap {
  position: relative;
  margin-top: 1rem;
  overflow: hidden;

  img {
    display: block;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    border: 0;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1;
    opacity: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
    cursor: pointer;
  }

  &:hover button:not(.disabled) {
    opacity: 0.9;
    transform: none;
  }

  button:not(.disabled):hover {
    opacity: 1;
  }

  > button {
    display: none;
  }
}

.product-thumbs-wrap button,
.product-thumbs .owl-nav .owl-prev,
.product-thumbs .owl-nav .owl-next {
  background-color: #fff;
  color: #666;
  font-size: 1.6rem;
}

.product-thumbs-wrap button,
.product-thumb::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 5px 0;
}

.product-thumbs {
  transition: top 0.3s ease-out;
  display: flex;

  &.owl-carousel {
    margin: 0 -0.5rem;
    width: calc(100% + 1rem);
  }

  .owl-stage {
    display: flex;
  }

  .owl-prev {
    transform: translateX(-100%);
    left: 0.5rem;
  }

  .owl-next {
    left: auto;
    right: 0.5rem;
    transform: translateX(100%);
  }
}

.product-thumb {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    transition: border-color 0.3s;
    border: 2px solid transparent;
  }

  &.active::before {
    border-color: $primary-color;
  }
}

// Product Detail
.product-single {
  color: inherit;
  padding-bottom: 36px;

  @media (max-width: 767px) {
    padding-bottom: 21px;
  }

  .product-details {
    // on product fullwidth page
    .container-fluid & {
      padding-top: 4px;
    }

    // on product with sidebar page
    aside + div & {
      padding-top: 2px;
    }

    .product-benefits {
      .benefits-text {
        color: #013220;
        font-size: 17px !important;
        margin: 0px 0px 3px !important;
        font-weight: 600;
      }

      .benefit {
        text-transform: capitalize;
      }
    }
  }

  .product-cat {
    margin-bottom: 1.3rem;
    @include print_css(product-single, categories);

    span {
      margin-right: 0.7rem;
    }
  }

  .detail-product-name {
    @include print_css(product-single, name);
    display: -webkit-box;
    // -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .product-tag {
    line-height: 1;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    color: $success-color;
    text-align: start !important;
    font-weight: 100;
    text-transform: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-price {
    display: block;
    margin-bottom: 1.1rem;
    @include css(color, product-single, price, color);
    @include css(font-size, product-single, price, font-size);
    @include css(font-weight, product-single, price, font-weight);
    @include css(letter-spacing, product-single, price, letter-spacing);
    line-height: 1;
  }

  .product-save {
    display: block;
    color: $secondary-color;
    @include css(font-size, product-single, old-price, font-size);
    @include css(font-weight, product-single, old-price, font-weight);
    @include css(letter-spacing, product-single, price, letter-spacing);
    line-height: 1;
  }

  .old-price {
    @include css(font-size, product-single, old-price, font-size);
    @include css(font-weight, product-single, old-price, font-weight);
    @include css(text-decoration, product-single, old-price, text-decoration);
  }

  .rating-reviews {
    &:not(:hover) {
      @include css(color, product-single, rating, review-color);
    }

    font-size: 1.2rem;
  }

  .ratings-container {
    font-size: 14px;
  }

  .ratings-full {
    margin-top: -2px;
  }

  .ratings::before {
    @include css(color, product-single, rating, color);
  }

  label {
    @include css(color, product-single, label, color);
    font-weight: 600;
    text-transform: uppercase;
  }

  .product-action {
    display: inline-block;

    .btn-wishlist {
      position: relative;
    }
  }

  .divider {
    margin-left: -1rem;
    margin-right: 1.8rem;
  }

  .social-links {
    //margin-right: 3rem;
    color: $grey-color;
  }

  .social-link {
    border: none;

    &.social-facebook:hover {
      background-color: transparent;
      color: #3b5998;
    }

    &.social-twitter:hover {
      background-color: transparent;
      color: #1da1f2;
    }

    &.social-pinterest:hover {
      background-color: transparent;
      color: #bd081c;
    }
  }

  .product-footer {
    > * {
      margin-bottom: 1rem;
    }
  }

  .btn-wishlist,
  .btn-compare {
    display: inline-block;
    padding: 0.5rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    background-color: transparent;
    transition: color 0.3s;
    text-transform: none;
    color: #666;

    i {
      display: inline-block;
      margin: 0 0.5rem 0.3rem 0;
      vertical-align: middle;
      font-size: 1.8rem;
      line-height: 0;
    }

    &:hover {
      color: $primary-color;
    }
  }

  .btn-compare {
    i {
      margin-right: 0.8rem;
      font-size: 2.1rem;
    }
  }

  .quantity {
    font-weight: 700;
  }

  .product-meta + .product-countdown-container {
    margin-top: 0.4rem;
  }

  .product-countdown-container {
    display: inline-flex;
    align-items: center;
    margin-bottom: 2.5rem;
    padding: 1rem 1.6rem 0.5rem;
    border: 1px solid #444;
    background-color: #444;
    text-transform: none;
    line-height: 1;
    color: #fff;
    border-radius: 0.3rem;
    flex-wrap: wrap;

    label {
      margin-right: 0.5rem;
      text-transform: none;
      color: #fff;
    }

    .product-countdown,
    .product-countdown-title {
      margin-bottom: 0.5rem;
    }

    .product-sale-info {
      position: relative;
      padding-right: 1.5rem;

      i {
        margin-right: 0.8rem;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 2.4rem;
        background: rgba(255, 255, 255, 0.2);
      }

      & + label {
        margin-left: 1.5rem;
      }
    }
  }

  .product-label-group {
    top: 0;
    right: 0;
    z-index: 2;
  }

  .product-label {
    color: #fff;
  }

  // variation - list box type
  .product-variations {
    > a:not(.size-guide) {
      @include css(width, product-single, variation, width);
      @include css(height, product-single, variation, height);
    }

    > .disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .color {
    border: 0;
  }

  .size {
    width: 3.6rem;
  }

  .accordion ul {
    list-style: none;
    padding-left: 0;
  }

  .rating-form,
  form {
    label {
      font-weight: 400;
      font-size: 1.3rem;
      text-transform: none;
      color: #666;
    }
  }

  .remian-time-lable {
    color: $success-color;
    padding: 0.3rem;
    text-align: center;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    background-color: #f3fce2;
  }

  .notify-container {
    padding-bottom: 20px;

    label {
      font-weight: 500;
      text-transform: none;
      color: $dark-color;
    }

    .out-of-stock {
      margin-bottom: 2rem;

      p:first-child {
        font-size: 2rem;
        font-weight: 600;
        color: $alert-color;
      }

      p {
        padding: 0;
      }
    }

    .notify-btn {
      padding: 1.2rem 0px;
      background-color: #e4eaec !important;
    }

    .check-mark {
      font-size: 2rem;
      color: $success-color;
    }
  }
}

.product-meta {
  margin-bottom: 1.8rem;
  @include css(color, product-single, product-meta, color);
  @include css(font-size, product-single, product-meta, font-size);

  span {
    margin: 0 1.9rem 0 3px;
  }
}

// Product Form
.product-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  @if (get(product-single, variation, height)) {
    line-height: get(product-single, variation, height);
  } @else if (get(product, variation, height)) {
    line-height: get(product, variation, height);
  }

  margin: 0 0 1rem;

  // label
  > label {
    min-width: 7rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.05em;
  }

  // select box type
  &.product-variations {
    line-height: 37px;
  }

  .select-box::before {
    right: 1.4rem;
    font-size: 1.2rem;
    color: #222;
  }

  select {
    max-width: none;
    padding: 0.8rem 3rem 0.8rem 1.4rem;
    color: #222;
    border-color: #ccc;
    font-size: 1.3rem;
  }

  // list box type
  .product-variations {
    display: block;
    margin-top: -3px;
    margin-bottom: -3px;
  }

  // quantity type
  &.product-qty {
    line-height: 4.5rem;
  }

  .quantity-minus {
    font-size: 22px;
    border: 1px solid #ccc;
  }

  .quantity-plus {
    font-size: 22px;
    border: 1px solid #ccc;
  }

  .input-group {
    margin-right: 1rem;
  }

  .btn-border {
    @media (min-width: 576px) {
      border-radius: 90px !important;
    }
  }

  .btn-cart {
    &.dark {
      background-color: $primary-color !important;
      color: white !important;
    }

    border: 0;
    flex: 1;
    min-width: 13rem;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    background-color: #c0ca7f;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    @include css(max-width, product-single, btn-cart, max-width);
    @include css(height, product-single, btn-cart, height);

    &.disabled {
      background-color: #e4eaec;
      cursor: not-allowed;
      color: $grey-color;
    }

    i {
      margin-right: 0.8rem;
      margin-top: -1px;
      font-size: 1.8rem;
      line-height: 0;
      vertical-align: middle;

      &::before {
        margin: 0;
      }
    }
  }
}

.linked-button {
  height: 15px;
}

.linked-product-input {
  height: 3.5rem !important;
}

// grouped control
.product-form-group {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;

  > * {
    margin-bottom: 1rem;
  }

  > :not(:last-child) {
    margin-right: 2rem;
  }

  // list box type
  .product-variations {
    margin-bottom: 7px;
  }
}

.size-guide {
  display: inline-flex;
  align-items: center;
  font-weight: 300;

  i {
    margin-right: 0.8rem;
    font-size: 2.1rem;
    color: #666;
  }
}

.product-variation-price {
  // display: none;
  // padding-top: 25px;
  span {
    margin-bottom: 1rem;
    color: #222;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
  }
}

.product-variation-clean {
  display: block;
  // position: absolute;
  max-width: 6.5rem;
  margin-top: 1rem;
  padding: 0.3em 1em;
  // left: 0;
  // top: calc(100% - 10px);
  font-size: 1rem;
  line-height: 1.6;
  background: #f4f4f4;
  color: #000;
}

//related products
.related-products {
  margin-top: 6.5rem;
}

@include mq(lg) {
  .pg-vertical {
    .product-thumbs-wrap {
      order: -1;
      max-width: 109px;
      margin: 0 1rem 0 0;
    }

    .product-single-carousel {
      max-width: calc(100% - 119px);
    }

    .product-thumbs {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .thumb-up,
    .thumb-down {
      display: flex;
      width: 100%;
      height: 2.4rem;
    }

    .thumb-up {
      transform: translateY(-100%);

      i::before {
        content: "\f077";
      }
    }

    .thumb-down {
      top: auto;
      transform: translateY(100%);

      i::before {
        content: "\f078";
      }
    }

    .product-label-group {
      left: 14rem;
    }
  }
}

// Product Sticky Both (new)
.product-single.product-sticky-both {
  .p-sticky {
    top: 88px;
  }

  .product-details {
    padding: 0;
  }
}

.product-tabs.tab-nav-simple .nav-link {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  color: $dark-color;
  letter-spacing: 0;
}

.product-image.large-image img {
  max-width: none;
}

.product-details.sticky {
  position: sticky;
  top: 88px;
}

.product-best-price-container {
  background: rgba(23, 179, 27, 0.05);
  padding: 1.2rem;
  border-radius: 5px;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: $success-color;
  margin-bottom: 20px;

  .product-top-content {
    display: flex;
    margin: 0;

    i {
      font-size: 1.4rem;
      margin-right: 0.5rem;
      color: $primary-color;
    }

    p {
      margin: 0;
      color: $primary-color;
      line-height: 1.5;
    }
  }

  .card {
    background: none !important;
    border-radius: 6px;
  }

  .coupon-list {
    color: inherit !important;
    font-size: 1.2rem;

    .card-header {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: right;

      .toggle-button {
        padding: 1rem 0rem !important;
      }
    }

    .coupon-subtext {
      font-size: 1.2rem !important;
    }

    .card-body {
      padding: 0;

      span {
        font-size: 1.2rem;
      }
    }
  }

  p {
    margin: 0;
    color: $primary-color;
  }

  .copy-code {
    text-decoration: underline;
    color: $text-color-3;
    // margin: 0px 0px 0px 5px;
  }
}

.product-best-price-container.wrap {
  border-color: #17b31b;
}

@include mq(sm, max) {
  .product-details {
    .detail-product-name {
      font-size: 2.5rem;
    }
  }
}

@include mq(xs, max) {
  .product-price {
    .old-price {
      font-size: 1.8rem;
    }

    .new-price {
      font-size: 2rem;
    }

    .product-save {
      font-size: 2rem;
    }
  }
}

.card-wrapper {
  flex: 0 0 calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 10px;
}

.card-image {
  /* min-height: 400px; */
  flex: 1 1 auto;
  max-height: 70vh;
}

.card-image img {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 20px;
}

.card-content {
  flex: 0 1 auto;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.card-content.wrapper {
  padding: 8px;
}

.card-content .tagline {
  font-weight: bolder;
  font-size: small;
  color: #50be77;
  margin-bottom: 0;
}

.card-content .price {
  font-size: small;
  margin-bottom: 0;
}

.card-content .add-to-cart-btn {
  margin: 2px 2px 10px 2px;
  border-radius: 25px;
  color: #50be77;
  border: 1px solid green;
  height: 50px;
}

@media only screen and (max-width: 767px) {
  .card-wrapper {
    width: 95%;
    flex: none;
    max-width: 100%;
    margin-left: 0;
  }
}

.card-slider-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 5px;
  scroll-behavior: smooth;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .card-slider-wrapper {
    overflow-x: auto;
    padding: 5px;
  }
}

.carousel-container {
  overflow-x: auto;
  padding: 5px;
}

.for-image {
  max-width: 100%;
  margin-right: 20px;
}

.for-text {
  font-family: "Reman Regular";
  font-size: 40px;
  color: #c0ca7f;
  font-weight: 900;
}

.for-subtext {
  padding-right: 20px;
  @media (min-width: 768px) {
    font-size: 15px;
  }
}

.icons-section-background {
  background-color: #e2e4e4;
  padding-top: 36px;
  padding-bottom: 36px;
}

.review-section-background {
  background-color: #fcfaf7;
  padding: 60px 0px;
}

.icons-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 10px;
}

.grid-item {
  text-align: center;
}

@media (max-width: 479px) {
  .icons-grid-container {
    grid-template-columns: 1fr;
  }
}

.icons-small {
  margin-bottom: 15px;
}

.icons-small-text {
  font-weight: 600;
}

.row-rev {
  @media (max-width: 767px) {
    flex-direction: column-reverse !important;
  }

  .image-card-content {
    min-height: auto !important;
  }
}

.product-detail-headings {
  color: #334641;
}

.product-detail-headings-2 {
  color: #334641;
  font-size: 24px;

  @media (max-width: 575px) {
    font-size: 18px;
  }
}

.accordion-border {
  border-top: 1px solid rgba(51, 70, 65, 0.2);
  border-bottom: 1px solid rgba(51, 70, 65, 0.2);
  border-left: none !important;
  border-right: none !important;
  justify-content: center !important;
}

.accordion-border.wrapper {
  max-width: 50%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.key-ingredients {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regime-flex {
  gap: 50px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
  }
}
.image-regime {
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.regime-section {
  padding: 2rem;

  .regime-step {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .regime-text {
    font-size: 24px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.how-to-use-section {
  background-color: #50be77;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  .how-to-use-container {
    padding: 32px;
  }

  .how-to-use-heading {
    font-size: 26.33px;
    color: #fff;
    text-align: center;
    padding-bottom: 50px;

    @media (max-width: 767px) {
      font-size: 21.09px;
    }
  }

  .how-to-use-grid {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .how-to-use-grid-items {
    width: 29.33%;
    margin-left: 4%;
    margin-right: 1%;
    color: #fff;

    @media (max-width: 767px) {
      width: 95%;
      margin-bottom: 30px;
    }
  }

  .how-to-use-subheading {
    margin-top: 20px;
    font-family: Reman Regular;
    font-weight: 500;
    font-style: italic;
  }
}

.linked-product {
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #fff;
  transform: translateY(-80%) !important;
}

.product-clip {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 18px;
  text-align: left;
}

.product-upsell {
  border: 1px solid rgba(51, 70, 65, 0.2);
}

.linked-product-form {
  width: 50%;
}

.border-img {
  border: 1px solid #1212120d;
  @media (min-width: 768px) {
    width: 50%;
  }
}
