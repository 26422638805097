/* -------------------------------------------
    Element Product Page
---------------------------------------------- */

.product-wrapper {
  &.row [class*="col-"] {
    margin-bottom: 2rem;
  }
}

.price-form {
  width: fit-content;
}

.btn-radius {
  border-radius: 90px;
}

.card-body ul {
  padding-left: 0;
  list-style-type: none;
}

.widget .owl-nav-top .owl-nav i {
  padding-left: 0;
  padding-right: 0;
}

.product-shadow {
  filter: brightness(98%);
}
