/* -------------------------------------------
    Checkout Page
---------------------------------------------- */

.checkout {
  line-height: 1.54;
  min-height: inherit;

  &.main {
    // border-top: 1px solid #e1e1e1;
  }

  .alert.card-header {
    padding-top: 1.2rem;
    padding-bottom: 1.3rem;
    background-color: $white-color;
    border: 1px dashed #cacbcc;
    text-transform: none;

    & > a {
      display: inline-block;
      padding: 0;

      &::after,
      &::before {
        content: none;
      }
    }
  }

  .alert-icon > i {
    margin-right: 0.8rem;
  }

  // .parse-content {
  &.expanded .text-body::after {
    width: 0.8rem;
    height: 0.8rem;
    left: 0.5rem;
    background: #666;
  }

  // }

  .alert-body {
    transition: display 0.3s;

    p {
      font-size: 1.3rem;
    }

    &.expanding {
      display: none;
    }

    &.collapsing {
      display: block;
    }

    .form-control {
      margin: 0;
    }
  }

  .checkbox {
    margin: 0.6rem 0 1.6rem 0.2rem;
  }

  .link-group {
    .btn {
      padding: 1em 2.5em;
    }

    span {
      margin: 0 1.1rem;
      font-size: 1.4rem;
    }
  }

  .lost-link {
    font-size: 1.3rem;
    color: #000;
  }

  .social-link {
    border-radius: 0.3rem;
    color: $white-color;

    &.social-google {
      background-color: $social-google;
      border-color: $social-google;
    }

    &.social-facebook {
      background-color: $social-facebook;
      border-color: $social-facebook;
    }

    &.social-twitter {
      background-color: $social-twitter;
      border-color: $social-twitter;
    }
  }

  .check-coupon-box {
    .form-control {
      max-width: 42.5rem;
    }

    .btn {
      padding: 1.04em 2.05em;
      border-width: 1px;
    }
  }

  label {
    display: block;
    padding-left: 0.2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 0.9;
    font-weight: 600;
  }

  .form .title.title-simple {
    margin-top: 0.3rem;
    font-size: 1.8rem;
  }

  .select-box select {
    max-width: 100%;
    width: 100%;
  }

  .form-control {
    margin-bottom: 2.1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.3rem;
    font-family: $font-family;
    transition: background-color 0.3s, border-color 0.3s;
    color: #999;
    min-height: 4.1rem;
    border-radius: 0.3rem;

    &:not(:focus) {
      background-color: #fff;
    }

    &:not(select):focus {
      border-color: #000;
    }
  }

  textarea.form-control {
    padding-top: 1.5rem;
  }

  .form-checkbox {
    padding-top: 0.7rem;
    margin-bottom: 2.8rem;
  }

  .form-control-label {
    padding-left: 2.6rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 2.58;
  }

  .order-image {
    min-width: 8rem;
  }

  .product-total {
    min-width: 6rem;
  }

  .product-name {
    font-size: 1.4rem;
    letter-spacing: 0;
    white-space: normal;
  }

  .btn-order {
    width: 100%;
    transition: opacity 0.3s;
    height: 6rem;

    &:hover {
      opacity: 0.9;
    }
  }

  .card {
    background: transparent;
  }

  .card-header {
    font-size: 1.4rem;
    line-height: 3rem;
    text-transform: capitalize;
    font-weight: 400;
    flex-direction: row;

    a {
      padding: 0 0 0 2.5rem;

      &::before,
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        left: 0;
        width: 1.8rem;
        height: 1.8rem;
        background: #fff;
        border: 1px solid #cdcdcd;
      }

      &.collapse::after {
        width: 0.8rem;
        height: 0.8rem;
        left: 0.5rem;
        background: #666;
      }
    }

    p {
      padding: 0px 1rem;
      color: #fafafa;
      border-radius: 0.3rem;
    }

    .extra-lable {
      background-color: $success-color;
      font-size: 12px;
      font-weight: 600;

      &.danger {
        background-color: $secondary-color;
      }
    }
  }

  .card-body {
    padding: 0.8rem 0 1.1rem 2.9rem;
    line-height: 1.4;
  }

  .payment.accordion {
    padding-bottom: 2rem;

    .summary-subtitle {
      font-size: 1.5rem;
    }
  }

  .checkout-info {
    padding: 1.5rem 0;
    margin: 0;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    font-family: $font-family;
  }

  .product-subtotal {
    margin-bottom: 0.5rem;

    .discount-percetage {
      color: $success-color;
    }
  }

  .product-quantity {
    margin-left: 0.4rem;
  }

  .title.title-simple {
    font-weight: 700;
  }

  .summary .form-control-label {
    line-height: 1.4;

    &::before {
      top: 0;
      transform: none;
    }

    a {
      color: #000;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .toggle-button.expanded::after,
  .toggle-button.expanding::after {
    width: 0.8rem;
    height: 0.8rem;
    left: 0.5rem;
    background: #666;
  }

  .product-discount-listing {
    display: inline;
  }

  .summary-subtotal-listingprice {
    margin-left: 1rem;
  }

  .payment-card {
    padding: 1rem;
    gap: 1rem;
    cursor: pointer;

    .checkout-payment-labels {
      font-size: 1.6rem;
      font-weight: 600;
      color: $primary-color !important;
    }

    .payment-subtitle {
      color: $grey-color;
    }

    .payment-alert {
      color: $secondary-color !important;

      a {
        width: fit-content;
        text-decoration: underline;
        display: block;
        cursor: pointer;
      }
    }

    .payment-card-amount {
      color: $primary-color;
      font-size: 1.6rem;
    }
  }

  .check-payment-detail {
    padding: 0rem 2rem 1rem;
  }

  .payment-heading {
    font-size: 2rem;
    margin-bottom: 1rem !important;
    margin-top: 2rem !important;
  }
}

.flex-no-wrap {
  flex-wrap: nowrap;
}
.address-card {
  border: 1px solid #e1e1e1;
  padding: 10px;
}

.fixed-width-container {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  width: 250px; // Set a fixed width
  white-space: nowrap; // Prevent text from wrapping
  overflow: hidden; // Hide overflowing text
  text-overflow: ellipsis; // Add "..."

  .add-lables-values {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
  }
  .add-bottom-btn {
    line-height: normal;
    background-color: #c0ca7f;
    font-weight: 600;
    color: #fff;
    border-radius: 6px;
    font-size: medium;
    width: fit-content;
    padding: 6px 12px;
    //	position: absolute;
    bottom: 1rem;
  }
}

.sticky-checkout {
  position: fixed;
  bottom: 0px;
  width: 80vw !important;
  max-width: 33rem;
}

.checkout-address-form {
  padding: 20px;
}

.checkout-sticky-btn {
  width: 60% !important;
}

.checkout-add-address-btn {
  padding: 2rem;
  align-items: center;
  justify-content: center;
  gap: 0.51rem;
  color: $primary-color;

  .add-address-label {
    font-size: 1.6rem;
    line-height: 1;
    font-weight: bolder;
  }
}

.checkout-summary-btn {
  color: $primary-color;
  font-size: 3rem;
  padding: 1.5rem 1.5rem;
  justify-content: space-between;

  .checkout-summary-label {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0px 12px 0px 8px !important;
  }

  .checkout-summary-total {
    font-size: 2rem;
    line-height: 1;
    font-weight: bold;
  }
}

.collapsible-checkout-wrapper {
  overflow: hidden;

  &.collapse:not(.show) {
    display: none;
  }

  &.collapsing {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 0.35s ease;
  }
}

.all-address-modal-overlay {
  margin: 0px;
  padding: 0px;

  .all-address-popup {
    padding: 1rem 1rem;
    border-radius: 16px 16px 0px 0px;
    position: absolute !important;
    bottom: 0;
    width: 100%;
    max-height: 550px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .address-card {
    border-bottom: 1px solid #e1e1e1;
  }
}

.summary .title {
  margin-bottom: 1.9rem;
  padding-bottom: 2.2rem;
  border-bottom: 1px solid #e1e1e1;
}

.order-table {
  color: #222;

  th {
    padding: 0 0 0.7rem 0;
    font-size: 1.6rem;
    font-weight: 600;
  }

  td {
    padding-bottom: 0.6rem;
    line-height: 1.6;

    time {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .order-total {
    td {
      border-bottom: none;
      padding: 1.2rem 0 0;
    }
  }

  .shipping-row {
    label {
      margin-bottom: 1.5rem;
    }
  }

  .shipping-row-last {
    border-bottom: 1px solid #e1e1e1;

    label {
      margin-bottom: 2.1rem;
      color: $body-color;
      cursor: pointer;
    }
  }

  .summary-subtotal {
    // border-top: 1px solid $border-color;
    td:first-child {
      padding: 0.4rem 0 0.3rem;
    }
  }

  .summary-subtotal-price {
    padding-top: 1rem;
  }

  // tbody > tr:nth-child(2) td {
  //     padding-bottom: 0.6rem;
  // }
}

.overflow-scrollbar-hidden {
  overflow: auto;
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Edge
  }
}

.element {
  /* Firefox */
}

.element::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.add-address-on-checkout {
  display: flex;

  padding: 15px;
  margin-bottom: 10px;
  background-color: #c0ca7f;
  align-items: center;
  gap: 8px;
  color: #fff;
  h5 {
    flex-grow: 1;
    margin: 0px;
    font-size: 1.6rem;
  }
  p {
    padding: 0px;
    margin: 0px;
  }
}

.sumnary-shipping {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .summary-subtitle {
    padding-bottom: 2.2rem;
    text-align: left;
  }
}

.summary-total {
  border-bottom: 1px solid $border-color;

  .summary-subtitle {
    padding: 2.3rem 0 2.1rem;
  }
}

@include mq(xs, "max") {
  .order-table {
    tbody {
      .product-name,
      .product-total {
        padding-bottom: 2rem;
        line-height: 1.2;
      }

      // > tr:nth-child(2) td {
      //     padding-bottom: 0.3rem;
      // }
    }
  }
}

@include mq(sm, "max") {
  .checkout-page-content {
    .container {
      padding: 0 !important;
    }
  }

  .payment-heading {
    margin-top: 1rem !important;
    padding-left: 1.5rem;
  }
}
