/* -------------------------------------------
	Variables 
------------------------------------------- */

// 1. Colors
$primary-color: #1a181e !default;
$primary-color-dark: #222 !default;
$primary-button-color: #c0ca7f !default;
$secondary-color: #e50b20 !default;
$alert-color: #b10001 !default;
$success-color: #c0ca7f !default;
$info-color: #26c !default;
$body-color: #666 !default;
$dark-color: #222 !default;
$light-color: #ccc !default;
$grey-color: #999 !default;
$border-color: #e1e1e1 !default;
$border-color-light: #eee !default;
$white-color: #fff !default;
$light-success-color: #f3fce2 !default;
$dark-green: #17b31b !default;
$sold-out-back: #c20000 !default;
$background-color-1: #f9f6e6 !default;
$background-color-2: #e9dbc4 !default;
$text-color-1: #334641 !default;
$text-color-2: #a37b64 !default;
$text-color-3: #50be77 !default;
$warning-color: #fff5eb !default;
$warning-color-dark: #ffa372 !default;

// 2. Font-families
$alt-font-family: sans-serif !default;
$second-font-family: Poppins, $alt-font-family !default;
$font-family: Inter, $alt-font-family !default;
$third-font-family: "Open Sans", $alt-font-family !default;

// 3. Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xlst: 1920px,
);
$max-breakpoints: (
  xs: 479px,
  sm: 575px,
  md: 767px,
  lg: 991px,
  xl: 1199px,
  xxl: 1599px,
);

// 4. Link Colors
$social-facebook: #3b5998;
$social-twitter: #1da1f2;
$social-linkedin: #0073b2;
$social-email: #dd4b39;
$social-google: #dd4b39;
$social-pinterest: #bd081c;
$social-reddit: #ff4107;
$social-tumblr: #304e6c;
$social-vk: #6383a8;
$social-whatsapp: #3c8a38;
$social-xing: #1a7576;
$social-instagram: #7c4a3a;
$social-paypal: #1a7576;
$social-youtube: #ff0000;
